import React from 'react';
import Layout from '../components/Layout';
import errorSvg from '../img/404.svg';
import { Link } from 'gatsby';

const NotFoundPage = () => (
  <Layout>
    <section className="hero is-fullheight is-light">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column is-6">
              <div className="box has-text-centered">
                <h2 className="title is-3 mb-4">¡Ups! Esta página no está disponible</h2>
                <p className="subtitle is-5 mb-4">La página que buscas no se encuentra.</p>
                <Link to="/" className="button is-info" style={{ backgroundColor: '#49509d' }}>
                  <strong>Regresar a la página principal</strong>
                </Link>
              </div>
            </div>
            <div className="column is-6">
              <figure className="image is-404">
                <img src={errorSvg} alt="Error 404" />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;